import { h, Component } from "preact";
import Chat from "./Chat";
import ToggleButton from "./ToggleButton";

export default class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: true,
        };

        // Bind the toggle method to `this`(App) object.
        this.toggle = this.toggle.bind(this);
    }

    render() {
        // If visible is true, we show the chat.
        if(this.state.visible) {
            return (<Chat toggle={this.toggle} />)
        }

        // If visible is false, we show the toggle button allowing the user
        // to show the chat component.
        return (<ToggleButton toggle={this.toggle} />)
    }

    // Toggle visibility
    toggle() {
        this.setState({visible: !this.state.visible});
    }
}
