import { h, Component } from "preact";
import { uploadGif, uploadImage, uploadVideo } from "../Services/images";


export default class Composer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: '',
            progress: 0,
            file: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEnterPress = this.onEnterPress.bind(this);
        this.upload = this.upload.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    onEnterPress(e) {
        const self = this;

        if(e.keyCode == 13 && e.shiftKey == false) {
            e.preventDefault();

            if(this.myTextarea.value == "") {
                return;
            }

            firebase.firestore().collection("rooms").doc("ipmc").collection("messages").add({
                type: "text",
                message: this.myTextarea.value,
                created: Date.now(),
                user: window.userref,
                user_id: window.user.uid,
            }).then(function(docref) {
                self.setState({value: ""});
                if(window.AppConfig.Debug) {
                    console.log("Document successfully written!", docref.id);
                }
            });
        }
    }

    render() {
        return (
            <div class="flex-none flex flex-col composer" data-type="composer">
                <div data-name="progress-bar" class="bg-gray-400 h-1 flex">
                    <div class="bg-green-400 flex justify-center flex-col whitespace-nowrap" style={"width:"+this.state.progress+"%;"}></div>
                </div>
                <form className="flex" ref={el => this.myFormRef = el}>
                    <textarea ref={el => this.myTextarea = el} class="p-2 w-full rounded-none" placeholder="Say something..." value={this.state.value} onKeyDown={this.onEnterPress}></textarea>
                    <div onClick={() => this.fileRef.click()} class="w-16 flex justify-center cursor-pointer">
                        <span class="w-8 h-full flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 h-8 icon-attach"><path class="secondary" d="M20.12 11.95l-6.58 6.59a5 5 0 1 1-7.08-7.07l6.59-6.6a3 3 0 0 1 4.24 4.25l-6.58 6.59a1 1 0 1 1-1.42-1.42l6.59-6.58a1 1 0 0 0-1.42-1.42l-6.58 6.59a3 3 0 0 0 4.24 4.24l6.59-6.58a5 5 0 0 0-7.08-7.08l-6.58 6.6a7 7 0 0 0 9.9 9.9l6.59-6.6a1 1 0 0 0-1.42-1.4z"/></svg>
                        </span>
                    </div>
                </form>
                <input type="file" class="hidden" name="upload" accept="image/png,image/jpeg,image/gif,video/mp4,video/mov,video/ogg,video/avi" ref={el => this.fileRef = el} onChange={this.upload} />
            </div>
        )
    }

    upload() {
        const self = this;
        let file = self.fileRef.files[0];
        switch(file.type) {
            case "image/jpeg":
            case "image/png":
                uploadImage("ipmc", file, self);
                break;
            case "image/gif":
                uploadGif("ipmc", file, self);
                break;
            case "video/mp4":
            case "video/mov":
            case "video/ogg":
            case "video/avi":
            case "video/quicktime":
                uploadVideo("ipmc", file, self);
                break;
        }

    }
}
