import { h, Component } from "preact";
import Post from "./Post";
import {useEffect, useState} from "preact/compat";

export default class Posts extends Component {
    render() {
        const [posts, setPosts] = useState(0);
        useEffect(() => {
            return firebase.firestore().collection("rooms").doc("ipmc").collection("messages").orderBy("created", "desc")
            .limit(20)
            .onSnapshot(function(query) {
                setPosts(query.docs.map(p => {
                    let d = p.data();
                    d.id = p.id;
                    d.userData = firebase.firestore().doc(d.user.path);
                    return (
                        <Post
                            key={"post-"+d.id}
                            data={d}
                            type={d.type}
                            message={d.message}
                        />
                    )
                }));
            });
        }, []);

        return (
            <div class="flex flex-grow flex-col-reverse overflow-y-scroll posts" data-type="posts">
                {posts}
            </div>
        )
    }
}
