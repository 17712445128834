import { h, Component } from "preact";

export default class Avatar extends Component {
    render() {
        return (
            <div class="mr-2 flex-none avatar" data-type="avatar">
                <img class="rounded-full w-8" src={this.props.url} alt=""/>
            </div>
        )
    }
}
