const MAX_WIDTH = 800;
const MAX_HEIGHT = 600;

function dataURItoBlob (dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type: mimeString});
}

export function uploadImage(room, fileRef, self) {
    var reader = new FileReader();
    reader.onload = function(e) {
        var img = document.createElement("img");
        img.src = e.target.result;
        img.onload = function() {
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var width = img.width;
            var height = img.height;

            if (width > height) {
                if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                }
            } else {
                if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                }
            }

            canvas.width = width;
            canvas.height = height;

            var resizedCanvas = document.createElement("canvas");
            resizedCanvas.width = width;
            resizedCanvas.height = height;
            var resizedCtx = resizedCanvas.getContext("2d");
            resizedCtx.drawImage(img, 0, 0, width, height);

            upload(
                room,
                "images",
                dataURItoBlob(resizedCanvas.toDataURL("image/jpeg", 1)),
                fileRef,
                self,
                "image"
            );
        }
    }

    reader.readAsDataURL(fileRef);
}

export function uploadGif(room, fileRef, self) {
    upload(room, "gifs", fileRef, fileRef, self, "image");
}

export function uploadVideo(room, fileRef, self) {
    upload(room, "videos", fileRef, fileRef, self, "video");
}

function upload(room, folder, file, fileRef, self, fileType) {
    var storageRef = window.firebase.storage().ref();
    var ref = storageRef.child(folder + '/' + uuidv4() + '/' + fileRef.name);

    let task = ref.put(file);

    task.on("state_changed", function(snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if(progress < 100) {
            self.setState({progress: progress});

        } else {
            self.setState({progress: 0});
        }
    }, function(error) {
        console.error(error);
    }, function() {
        task.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            firebase.firestore().collection("rooms").doc(room).collection("messages").add({
                type: fileType,
                url: downloadURL,
                created: Date.now(),
                user: window.userref,
                user_id: window.user.uid,
                mime: file.type,
            }).then(function(docref) {
                if(window.AppConfig.Debug) {
                    console.log("Document successfully written!", docref.id);
                }
            });
        });
    })
}
