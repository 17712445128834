import { h, Component } from "preact";
import MessageHeader from "./MessageHeader";

export default class Message extends Component {
    render() {
        // Did we receive or send this message?
        let io = "bg-gray-700";
        if(this.props.sender) {
            io = "bg-blue-700";
        }

        let message = this.props.message;
        if(this.props.message.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig)) {
            let url = this.props.message.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
            message = (
                <a href={url} target="_BLANK">{message}</a>
            )
        }

        return (
            <div class={io + " p-2 w-full message"} data-sender_id={this.props.data.user_id} data-message_id={this.props.id}>
                <MessageHeader
                    id={this.props.data.id}
                    ago={this.props.ago}
                    name={this.props.name}
                    sender={this.props.sender}
                />
                <div class="text-white">
                    {message}
                </div>
            </div>
        )
    }
}
