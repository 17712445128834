import { h, Component } from "preact";
import {useEffect, useState} from "preact/compat";
import Avatar from "./Avatar";
import Message from "./Message";
import Image from "./Image";
import Video from "./Video";

export default class Post extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            avatar: "",
        };
    }

    render() {
        let body = "";
        let self = this;

        const [user, setUser] = useState(0);
        useEffect(() => {
            self.props.data.userData.get().then((doc) => {
                setUser(doc.data());
            })
        }, []);

        let sender = false;
        if(this.props.data.user_id == window.user.uid) {
            sender = true;
        }

        if(this.props.type == "text") {
                body = <Message
                    id={this.props.data.id}
                    ago={this.props.data.created}
                    data={this.props.data}
                    name={user.name}
                    sender={sender}
                    sender_id={this.state.sender_id}
                    message={this.props.message}
                />
        }
        if(this.props.type == "image") {
            body = <Image
                    id={this.props.data.id}
                    ago={this.props.data.created}
                    data={this.props.data}
                    name={user.name}
                    sender={sender}
                    sender_id={this.state.sender_id}
                    url={this.props.data.url}
                />
        }
        if(this.props.type == "video") {
            body = <Video
                    id={this.props.data.id}
                    ago={this.props.data.created}
                    data={this.props.data}
                    name={user.name}
                    sender={sender}
                    sender_id={this.state.sender_id}
                    url={this.props.data.url}
                />
        }
        return (
            <div class="p-2 max-w-full post" data-type="post" id={"post_" + this.props.data.id}>
                <div class="flex">
                    <Avatar
                        url={user.avatar}
                    />
                    {body}
                </div>
            </div>
        )
    }
}
