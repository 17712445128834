import { h, Component } from "preact";
import MessageHeader from "./MessageHeader";
import VideoPlayer from "./VideoPlayer";

export default class Video extends Component {
    render() {
        // Did we receive or send this message?
        let io = "bg-gray-700";
        if(this.props.sender) {
            io = "bg-blue-700";
        }

        let url = this.props.url;

        if(window.AppConfig.VideoCDN) {
            url = url.replace("firebasestorage.googleapis.com", "video.bulldog.workers.dev");
        }

        const videoJsOptions = {
            autoplay: false,
            responsive: true,
            controls: true,
            sources: [{
                src: url,
            }]
        }

        return (
            <div class={io + " text-white p-2 w-full video"}>
                <MessageHeader
                    id={this.props.data.id}
                    ago={this.props.ago}
                    name={this.props.name}
                    sender={this.props.sender}
                />
                <VideoPlayer { ...videoJsOptions } />
            </div>
        )
    }
}
