import { h, render } from "preact";
import App from "./Components/App";

window.AppConfig = {
    Debug: false,
    VideoCDN: false,
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

if(urlParams.has("jwt")) {
    firebase.auth().signInWithCustomToken(urlParams.get("jwt"))
    .then((res) => {
        let parts = urlParams.get("jwt").split(".");
        let jwt = atob(parts[1]);
        let data = JSON.parse(jwt);

        if(!res.user.email) {
            res.user.updateEmail(data.email);
        }

        res.user.updateProfile({
            displayName: data.username,
            photoURL: data.avatar
        })
    })
    .catch((error) => {
        // Invalid assertion format. 3 dot separated segments required.
        // The custom token format is incorrect. Please check the documentation.
        console.error(error.message);
    })
}

firebase.auth().onAuthStateChanged((user) => {
    if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        var uid = user.uid;
        window.user = user;
        window.userref = firebase.firestore().collection("users").doc("ipmc").collection("members").doc(user.uid);

        render(<App />, document.getElementById("App"));

        firebase.firestore().collection("users").doc("ipmc").collection("members").doc(user.uid).set({
            name: user.displayName,
            avatar: user.photoURL
        });
    } else {
        // User is signed out
        // ...
    }
});
