import { h, Component } from "preact";
import Composer from "./Composer";
import Header from "./Header";
import Posts from "./Posts";

export default class Chat extends Component {
    render() {
        return (
            <div class="w-full flex flex-col md:w-full absolute bottom-0 left-0 h-screen bg-chat transition-all chat">
                <Header toggle={this.props.toggle} />
                <Posts />
                <Composer />
            </div>
        )
    }
}
