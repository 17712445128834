import { h, Component } from "preact";

export default class MessageHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ago: moment(this.props.ago, "x").fromNow(),
            showDelete: false,
        };

        this.toggle = this.toggle.bind(this);
        this.delete = this.delete.bind(this);
    }

    render() {
        let deleteClass = "hidden";
        let agoClass = "";

        if(this.state.showDelete) {
            deleteClass = "";
            agoClass = "hidden";
        }

        return (
            <div class="mb-2 flex items-baseline justify-between message-header">
                <div class="font-bold text-white">{this.props.name}</div>
                <div class="text-sm text-gray-400">
                    <span class={deleteClass}>
                        Delete? <span class="hover:underline cursor-pointer" onClick={this.delete}>Yes</span> / <span class="hover:underline cursor-pointer" onClick={this.toggle}>No</span>
                    </span>
                    <span onClick={this.toggle} class={agoClass + " hover:underline cursor-pointer"}>{this.state.ago}</span>
                </div>
            </div>
        )
    }

    toggle() {
        if(!this.props.sender) {
            return;
        }

        this.setState({showDelete: !this.state.showDelete});
    }

    delete() {
        if(!this.props.sender) {
            return;
        }

        firebase.firestore()
            .collection("rooms")
            .doc("ipmc")
            .collection("messages")
            .doc(this.props.id)
            .delete()
            .then(function() {
                if(window.AppConfig.Debug) {
                    console.log("document deleted");
                }
            })
            .catch(function(err) {
                console.error(err);
            });
    }
}
