import { h, Component } from "preact";

export default class Header extends Component {
    render() {
        return (
            <div class="flex flex-none justify-between items-center border-b border-gray-600 p-4 text-gray-200 header" data-type="header">
                <span>Chat</span>
                <button
                    type="button"
                    class="cursor-pointer"
                    onClick={this.props.toggle}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 icon-close"><path class="secondary" fill-rule="evenodd" d="M15.78 14.36a1 1 0 0 1-1.42 1.42l-2.82-2.83-2.83 2.83a1 1 0 1 1-1.42-1.42l2.83-2.82L7.3 8.7a1 1 0 0 1 1.42-1.42l2.83 2.83 2.82-2.83a1 1 0 0 1 1.42 1.42l-2.83 2.83 2.83 2.82z"/></svg>
                </button>
            </div>
        )
    }
}
