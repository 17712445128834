import { h, Component } from "preact";
import MessageHeader from "./MessageHeader";

export default class Image extends Component {
    render() {
        // Did we receive or send this message?
        let io = "bg-gray-700";
        if(this.props.sender) {
            io = "bg-blue-700";
        }

        let url = this.props.url;
        url = url.replace("firebasestorage.googleapis.com", "imageproxy.bulldog.dev");
        return (
            <div class={io + " text-white p-2 w-full image"}>
                <MessageHeader
                    id={this.props.data.id}
                    ago={this.props.ago}
                    name={this.props.name}
                    sender={this.props.sender}
                />
                <div>
                    <img class="max-w-full" src={url} alt={this.props.name} />
                </div>
            </div>
        )
    }
}
