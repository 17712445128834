import { h, Component } from "preact";

export default class ToggleButton extends Component {
    render() {
        return (
            <div class="absolute bottom-5 left-0 border-r border-t border-b flex items-center justify-center bg-gray-200 toggle-button">
                <button
                    type="button"
                    class="cursor-pointer p-4"
                    onClick={this.props.toggle}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-8 icon-chat-group"><path class="primary" d="M20.3 12.04l1.01 3a1 1 0 0 1-1.26 1.27l-3.01-1a7 7 0 1 1 3.27-3.27zM11 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/><path class="secondary" d="M15.88 17.8a7 7 0 0 1-8.92 2.5l-3 1.01a1 1 0 0 1-1.27-1.26l1-3.01A6.97 6.97 0 0 1 5 9.1a9 9 0 0 0 10.88 8.7z"/></svg>
                </button>
            </div>
        )
    }
}
